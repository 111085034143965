<div class="container">
  <app-list-agency-header></app-list-agency-header>

  <!-- TAB -->
  <mat-card id="card-list-waiver-new">
    <mat-card-content id="card-content-list-waiver-new">
      <mat-tab-group #tabGroup>
        <mat-tab id="card-list-waiver-new-pending">
          <ng-template mat-tab-label>
            <span>{{ 'pendings' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsPendingStatus" [tab]="'pendientes'" [user]="userProfiles.roleAgency ? 'agency' : 'agent'"></app-waiver-list-postsale>
        </mat-tab>

        <mat-tab id="card-list-waiver-new-approved">
          <ng-template mat-tab-label>
            <span>{{ 'approveds' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsApprovedStatus" [tab]="'aprobados'" [user]="userProfiles.roleAgency ? 'agency' : 'agent'"></app-waiver-list-postsale>
        </mat-tab>

        <mat-tab id="card-list-waiver-new-rejected">
          <ng-template mat-tab-label>
            <span>{{ 'denieds' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsRejectedStatus" [tab]="'rechazados'" [user]="userProfiles.roleAgency ? 'agency' : 'agent'"></app-waiver-list-postsale>
        </mat-tab>

        <mat-tab id="card-list-waiver-pending-agency">
          <ng-template mat-tab-label>
            <span>{{ 'pendingsAgency' | translate }}</span>
          </ng-template>

          <app-waiver-list-postsale [displayedColumns]="displayedColumnsPendingAgencyStatus" [tab]="'pendientes-agencia'" [user]="userProfiles.roleAgency ? 'agency' : 'agent'"></app-waiver-list-postsale>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
