<header>
    <div class="header-waivers-latam">
        <div class="header-left">
            <a class="logo-latam-header" href="/agency/home">
                <img class="latam-logo" src="/assets/images/latam-airlines.svg" alt="Latam airlines logo">
            </a>
        </div>
        <div class="header-center">
        </div>
        <div class="header-right">
            <button  class="language-button" mat-button [matMenuTriggerFor]="menu">
                <img style="width: 20px" src="assets/images/waiver-latam/{{languageItem.img}}" />
                {{ languageItem.translateName | translate }}
                <img src="assets/images/waiver-latam/caretdown.svg" />
            </button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let item of languages">
                    <button *ngIf="item.shortName !== language" mat-menu-item (click)="changedLanguage(item.shortName)">
                        <img style="width: 22px" src="assets/images/waiver-latam/{{item.img}}" />
                        {{ item.translateName | translate}}
                    </button>
                </ng-container>
            </mat-menu>

            <div class="budget-header" *ngIf="showAgencyBudget">
                <span style="margin-right: 5px;">
                    Saldo:
                </span>
                <span *ngIf="isLoadingAgencyBudget"
                >
                    <mat-progress-spinner 
                        class="budget-spinner"
                        mode="indeterminate" 
                        diameter="20" 
                    >
                    </mat-progress-spinner>
                </span>
                <span *ngIf="!isLoadingAgencyBudget"
                > 
                     USD {{agencyGroupBudget | formatNumber | dashWhenEmpty}}
                </span>
            </div>

            <button mat-button class="logout-button" (click)="logout()">
                <span>{{ 'button.logout' | translate}}</span>
            </button>
        </div>
    </div>
</header>
