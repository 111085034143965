import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AgencyGroupService } from '../../services/agency-group.service';
import { BudgetService } from '../../services/budget.service';
import { ProfilingService } from '../../services/profiling.service';
import { SessionInfoService } from '../../services/session-info.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonsService } from '../../services/commons.service';
import { LoginAgencyService } from '../../services/login-agency.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-waiver-latam-header',
    templateUrl: './waiver-latam-header.component.html',
    styleUrls: ['./waiver-latam-header.component.css']
})
export class WaiverLatamHeaderComponent implements OnInit{

  isLoadingAgencyBudget: boolean = false;
  showAgencyBudget: boolean = false;
  agencyGroupBudget: number = 0;
  language: string;
  languages: any[];
  languageItem: any;
  reloadBudgetSubscription: Subscription;

  constructor(private profilesService: ProfilingService,
      private sessionInfoService: SessionInfoService,
      private budgetService: BudgetService,
      private translateService: TranslateService,
      private commonsService: CommonsService, 
      private loginAgencyService: LoginAgencyService){
  
    }

  ngOnInit(): void {
    this.language = localStorage.getItem("language");
    this.getLanguages();
    this.languageItem = this.languages.find(item => item.shortName === this.language);
    this.loadBudget();
    this.reloadBudgetSubscription = this.budgetService.reload.subscribe( () => {
      this.loadBudget();
    });

  }

  selectItem(item: string) {
    this.language = item;
  }

  loadBudget(){
      this.isLoadingAgencyBudget = true;
      this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        sessionInfo => {
          this.getAmountAvailable(sessionInfo.matrix_id);
        }
      )
      .catch(
        error => {
          console.log('error', error);
        }
      );
  }

  private getAmountAvailable(agencyGroupId) {
      this.budgetService.getMonthlyAfterSalesBudgetByGroup(agencyGroupId).subscribe({
        next: data => {
          this.agencyGroupBudget = parseFloat((data.assignedAmount - data.busyAmount).toFixed(2));
          this.showAgencyBudget = data?.agencyGroup?.showBudget;
          this.isLoadingAgencyBudget = false;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoadingAgencyBudget = false;
          console.log('error getAmountAvailable', error);
        }
    });
  }

  changedLanguage(value: string) {
    this.language = value;
    this.languageItem = this.languages.find(item => item.shortName === this.language);
    localStorage.setItem("language", this.language);
    this.translateService.use(this.language);
    
  }

  getLanguages() {
    this.languages = this.commonsService.getLenguagesV2();
  }

  logout() {
    this.loginAgencyService.logout(false);
  }
}
