import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouteReuseStrategy } from '@angular/router';

import { ShareModule } from '../share-module/share.module';

/* import component */
import { ActivateAgencyComponent } from './activate-agency/activate-agency.component';
import { AgencyComponentsReuseStrategy } from './agency-components-reuse-strategy';
import { CreateEditUserComponent } from '../user-module/create-edit-user/create-edit-user.component';
import { ProfileAgencyComponent } from './profile-agency/profile-agency.component';
import { RegisterAgencyComponent } from './register-agency/register-agency.component';
import { SearchAgencyComponent } from './search-agency/search-agency.component';

/* import service */
import { AgencyBranchOfficeService } from './../../services/agency-branch-office.service';
import { AgencyGroupService } from '../../services/agency-group.service';
import { AgencyPiaService } from './../../services/agency-pia.service';
import { AgencyService } from '../../services/agency.service';
import { MarketService } from './../../services/market.service';
import { UserService } from '../../services/user.service';


@NgModule({
    declarations: [
        ActivateAgencyComponent,
        ProfileAgencyComponent,
        RegisterAgencyComponent,
        SearchAgencyComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        ReactiveFormsModule,
        ShareModule
    ],
    providers: [
        AgencyBranchOfficeService,
        AgencyGroupService,
        AgencyPiaService,
        AgencyService,
        MarketService,
        UserService,
        {
            provide: RouteReuseStrategy,
            useClass: AgencyComponentsReuseStrategy
        }
    ]
})
export class AgencyModule { }
