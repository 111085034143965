import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ParamMap } from '@angular/router';

import { SessionInfoService } from '../services/session-info.service';


@Injectable()
export class SessionAuthLatamGuard implements CanActivate {

  constructor(
    private router: Router,
    private sessionInfoService: SessionInfoService) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    this.sessionInfoService.getSessionInfoAsPromise()
      .then(
        sessionInfo => {
          if (sessionInfo && sessionInfo.isAgencySession()) {
            this.router.navigateByUrl('/401', { skipLocationChange: true });
            return false;
          }
          this.sessionInfoService.validateRolePath(route, state, sessionInfo.role);
    });

    if (localStorage.getItem('idToken') && localStorage.getItem('sessionToken')) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}
