import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { enableProdMode, NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localePt from '@angular/common/locales/pt';

// Environment
import { environment } from './../environments/environment';

// Modules
import { AgencyGroupModule } from './modules/agency-group-module/agency-group.module';
import { AgencyModule } from './modules/agency-module/agency.module';
import { BranchOfficeModule } from './modules/branch-office-module/branch-office.module';
import { CustomMatPaginatorIntl } from './modules/share-module/pagination-translation';
import { ErrorModule } from './modules/error-module/error.module';
import { LatamModule } from './modules/latam-module/latam.module';
import { LoginModule } from './modules/login-module/login.module';
import { ShareModule } from './modules/share-module/share.module';
import { TermsConditionsModule } from './modules/terms-conditions-module/terms-conditions.module';
import { UserModule } from './modules/user-module/user.module';
import { WaiverPostsaleModule } from './modules/waiver-postsale-module/waiver-postsale.module';
import { WaiverPresaleModule } from './modules/waiver-presale-module/waiver-presale.module';

// Guard
import { SessionAuthAgencyGuard } from './guards/session-auth-agency.guard';
import { SessionAuthLatamGuard } from './guards/session-auth-latam.guard';

// Routing
import { AppRoutingModule } from './app.routing';

// Components
import { AppComponent } from './app.component';

// Services
import { CommonsService } from './services/commons.service';
import { ConstantsService } from './services/constants.service';
import { KeepAliveService } from './services/keep-alive.service';
import { LoaderService } from './services/loader.service';
import { LoginService } from './services/login.service';
import { MessageService } from './services/message.service';
import { ProfilingService } from './services/profiling.service';
import { RouteLocaleExtractorService } from './services/route-locale-extractor.service';
import { SessionInfoService } from './services/session-info.service';
import { SidebarShareDataService } from './services/sidebar-share-data.service';
import { UrlService } from './services/url.service';
import { BdaService } from './services/bda.service';

// Interceptor
import { TokenInterceptor } from './interceptors/token.interceptor';
import { StatusTicketService } from './services/status-ticket.service';
import { SessionAuthOutsourcingGuard } from './guards/session-auth-outsourcing.guard';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { StorageService } from './services/storage.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { WaiverPostsaleAutoModuleModule } from './modules/waiver-postsale-auto-module/waiver-postsale-auto-module.module';

// language setting
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// formato para las fechas
export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'DD-MMM-YYYY',
    dateA11yLabel: 'DD-MMM-YYYY',
    monthYearA11yLabel: 'DD-MMM-YYYY',
  },
};

// locale
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt, 'it');
registerLocaleData(localePt, 'pt');

export class DynamicLocaleId extends String {
  constructor(protected translateService: TranslateService) {
    super();
  }

  toString() {
    return this.translateService.currentLang;
  }
}


enableProdMode();
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AgencyGroupModule,
    AgencyModule,
    AngularFireModule.initializeApp({apiKey: StorageService.getApiKey(),
       authDomain: StorageService.getAuthDomain()}),
    AngularFireAuthModule,
    AppRoutingModule,
    BranchOfficeModule,
    BrowserAnimationsModule,
    CommonModule,
    ErrorModule,
    FormsModule,
    HttpClientModule,
    LatamModule,
    LoginModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    ShareModule,
    TermsConditionsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    UserModule,
    WaiverPostsaleModule,
    WaiverPresaleModule,
    WaiverPostsaleAutoModuleModule,
    MatDialogModule
  ],
  providers: [
    SessionAuthAgencyGuard,
    SessionAuthLatamGuard,
    SessionAuthOutsourcingGuard,
    CommonsService,
    ConstantsService,
    KeepAliveService,
    LoaderService,
    LoginService,
    MessageService,
    ProfilingService,
    RouteLocaleExtractorService,
    SessionInfoService,
    SidebarShareDataService,
    UrlService,
    BdaService,
    StatusTicketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [TranslateService]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMAT
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {appareance: 'fill'}
    }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AppModule { }
