<div class="modal-header">
  <div class="row ml-0 mr-0">
    <div class="col-11 col-md-6 col-lg-6 col-xl-7 order-3 order-md-1 order-lg-1 order-xl-1 title">
      <p>{{ ('requestDetailId' | translate) + ' ' + idParam }}</p>
    </div>

    <div
      class="col-11 col-md-5 col-lg-5 col-xl-4 order-1 order-md-2 order-lg-2 order-xl-2 pt-0 pl-0 pb-0 pr-0 alert-waiver-latam alert"
      [ngClass]="{ 'alert-success': tab === 'aprobados', 'alert-warning': tab === 'pendientes', 'alert-error': tab === 'rechazados' }">
      <div class="alert-icon"
        [ngClass]="{ 'alert-icon-success': tab === 'aprobados', 'alert-icon-warning': tab === 'pendientes', 'alert-icon-error': tab === 'rechazados' }">
        <ng-container *ngIf="tab === 'aprobados'">
          <i class="material-icons">done</i>
        </ng-container>
        <ng-container *ngIf="tab === 'pendientes'">
          <i class="material-icons">query_builder</i>
        </ng-container>
        <ng-container *ngIf="tab === 'rechazados'">
          <i class="material-icons">clear</i>
        </ng-container>
      </div>

      <div class="alert-text w-100"
        [ngClass]="{ 'alert-text-warning': tab === 'pendientes', 'alert-text-error': tab === 'rechazados' }">
        <ng-container *ngIf="tab === 'aprobados'">
          <p class="mb-0">{{ 'approved' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="tab === 'pendientes'">
          <p class="mb-0">{{ 'pending' | translate }}</p>
        </ng-container>
        <ng-container *ngIf="tab === 'rechazados'">
          <p class="mb-0">{{ 'denied' | translate }}</p>
        </ng-container>
      </div>
    </div>

    <div class="col-1 order-2 order-md-3 order-lg-3 order-xl-3 pr-0">
      <div class="close-modal">
        <button class="btn-close-modal" (click)="closeDialog()">
          <mat-icon class="close-icon">clear</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-content detail-modal">
  <div *ngIf="loading || loadingData" class="loading">
    <div class="loading-div">
      <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
      </svg>
      <ng-container *ngIf="loadingData">
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </ng-container>
      <ng-container *ngIf="loading">
        <span class="loading-span">{{ 'sendingRequest' | translate }}</span>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!loading && !loadingData">
    <div class="modal-content-body">
      <!-- info solicitud -->
      <div class="row mt-2 ml-3 mr-3">
        <div class="col">
          <span class="subtitle">{{ 'requestInformation' | translate }}</span>
        </div>
      </div>

      <div class="table-waiver-latam mat-elevation-z1">
        <mat-table [dataSource]="dataSolicitud">
          <ng-container matColumnDef="solicitante">
            <mat-header-cell *matHeaderCellDef>{{ 'field.requestedBy' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">
              {{ (data.user.name | dashWhenEmpty) + ' ' + (data.user.lastname | dashWhenEmpty) }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="agency">
            <mat-header-cell *matHeaderCellDef>{{ 'field.agency' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">
              {{ (data.branchOffice ? data.branchOffice.name : data.agencyGroup.name) | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="tier">
            <mat-header-cell *matHeaderCellDef>{{ 'field.tier' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">
              {{ data.agencyGroup ? (data.agencyGroup.agencyTier ? data.agencyGroup.agencyTier.name : '—' ) : '—' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="iata">
            <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.documentIata | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <mat-header-cell *matHeaderCellDef>{{ 'field.type' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.type.name | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="motivo">
            <mat-header-cell *matHeaderCellDef>{{ 'field.reason' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.motive.name | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="date_hour">
            <mat-header-cell class="date-hour-arrow" *matHeaderCellDef>{{ 'field.requestDateHour' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let data">
              {{ data.creationDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns" class="waiver-latam-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="waiver-latam-row"></mat-row>
        </mat-table>
      </div>

      <div class="table-waiver-latam mat-elevation-z1 mt-4">
        <mat-table #table [dataSource]="dataSolicitud">
          <ng-container matColumnDef="pnrAgencia">
            <mat-header-cell *matHeaderCellDef>{{ 'field.agencyPnr' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.pnrAgency | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="files">
            <mat-header-cell *matHeaderCellDef>{{ 'field.attachedFiles' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">
              <span *ngIf="data.files.length === 0">—</span>
              <app-download-list *ngIf="data.files.length!=0" [files]="data.files"></app-download-list>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="coment">
            <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let data">{{ data.aditionalComment | dashWhenEmpty }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsCo" class="waiver-latam-row"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsCo;" class="waiver-latam-row"></mat-row>
        </mat-table>
      </div>

      <!-- info aprobado -->
      <ng-container *ngIf="tab == 'aprobados'">
        <div class="row mt-4 ml-3 mr-3">
          <div class="col">
            <span class="subtitle">{{ 'approvalInformation' | translate }}</span>
          </div>
        </div>

        <div class="table-waiver-latam mat-elevation-z1">
          <mat-table [dataSource]="dataAprobacion">
            <ng-container matColumnDef="nameAprob">
              <mat-header-cell *matHeaderCellDef>{{ 'field.approvedBy' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.statusUser?.name | dashWhenEmpty }}
                {{ data.statusUser?.lastname | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateAprob">
              <mat-header-cell *matHeaderCellDef>{{ 'field.approvalDateHour' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ data.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="commentAprob">
              <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.statusComment | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="folioWaiverAprob">
              <mat-header-cell *matHeaderCellDef>{{ 'field.waiverRecord' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.folio | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="waiverValueAprob">
              <mat-header-cell *matHeaderCellDef>{{ 'field.waiverValueUsd' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.valorization | number: '1.2-2':'en-US' | dashWhenEmpty }}
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsA" class="waiver-latam-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsA;" class="waiver-latam-row"></mat-row>
          </mat-table>
        </div>
      </ng-container>

      <!-- info rechazo -->
      <ng-container *ngIf="tab == 'rechazados'">
        <div class="row mt-4 ml-3 mr-3">
          <div class="col">
            <span class="subtitle">{{ 'denialInformation' | translate }}</span>
          </div>
        </div>

        <div class="table-waiver-latam mat-elevation-z1">
          <mat-table [dataSource]="dataRechazo">
            <ng-container matColumnDef="nameRech">
              <mat-header-cell *matHeaderCellDef>{{ 'field.deniedBy' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ (data.statusUser?.name | dashWhenEmpty) + ' ' + (data.statusUser?.lastname | dashWhenEmpty) }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="dateRech">
              <mat-header-cell *matHeaderCellDef>{{ 'field.denialDateHour' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ data.statusDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="motivoRech">
              <mat-header-cell *matHeaderCellDef>{{ 'field.denialReason' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.rejectionMotive?.name | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="commentRech">
              <mat-header-cell *matHeaderCellDef>{{ 'field.remarks' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.statusComment | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsR" class="waiver-latam-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsR;" class="waiver-latam-row"></mat-row>
          </mat-table>
        </div>
      </ng-container>

      <!-- info pnr -->
      <ng-container *ngIf="objectWaiver.document == 'pnr'">
        <div class="row mt-4 mr-0 ml-0">
          <div class="col text-center title">
            <p>{{ 'pnrDetail' | translate }}</p>
          </div>
        </div>

        <!-- info reserva -->
        <div class="row mr-3 ml-3">
          <div class="col">
            <span class="subtitle">{{ 'bookingInformation' | translate }}</span>
          </div>
        </div>

        <div class="table-waiver-latam mat-elevation-z1">
          <mat-table [dataSource]="dataReservation">
            <ng-container matColumnDef="pnrNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pnrType">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pnrType' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentPnrType | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="reservationCreationDate">
              <mat-header-cell *matHeaderCellDef>{{ 'field.bookingCreation' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ data.documentCreationDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="numberOfPassengers">
              <mat-header-cell *matHeaderCellDef>{{ 'field.numberPassengers' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ dataPassengers?.data?.length }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pos" id="detail-list-waiver-latam-pos">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentPos | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="iata" id="detail-list-waiver-latam-iata">
              <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentIata | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pcc" id="detail-list-waiver-latam-pcc">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentPcc | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="officeId" id="detail-list-waiver-latam-officeId">
              <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentOfficeId | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsP" class="waiver-latam-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsP;" class="waiver-latam-row"></mat-row>
          </mat-table>
        </div>

        <!-- info pasajero -->
        <div class="row mt-4 mr-3 ml-3">
          <div class="col">
            <span class="subtitle">{{ 'passengerInfo' | translate }}</span>
          </div>
        </div>

        <div class="table-waiver-latam mat-elevation-z1">
          <mat-table [dataSource]="dataPassengers">
            <ng-container matColumnDef="passengerName">
              <mat-header-cell *matHeaderCellDef>{{ 'field.passengerName' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ (data.firstName | partialDisplay: '#' : 1 | dashWhenEmpty) + ' ' + (data.lastName | partialDisplay: '#' : 1 | dashWhenEmpty) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="foid" id="detail-list-waiver-latam-foid">
              <mat-header-cell *matHeaderCellDef>{{ 'field.foid' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.foid | partialDisplay:'#' :-2 | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="passengerType" id="detail-list-waiver-latam-passengerType">
              <mat-header-cell *matHeaderCellDef>{{ 'field.passengerType' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.type | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <!-- Sólo se muestra si el waiver es post-venta -->
            <ng-container matColumnDef="passengerTKT" id="detail-list-waiver-latam-passengerTKT">
              <mat-header-cell *matHeaderCellDef>{{ 'field.associatedTkt' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.ticketNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsI" class="waiver-latam-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsI;" class="waiver-latam-row"></mat-row>
          </mat-table>
        </div>

        <!-- info segmento -->
        <div class="row mt-4 mr-3 ml-3">
          <div class="col">
            <span class="subtitle">{{ 'segmentInformation' | translate }}</span>
          </div>
        </div>

        <div class="table-waiver-latam mat-elevation-z1 mb-3">
          <mat-table [dataSource]="dataSegments">
            <ng-container matColumnDef="segmentNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.segmentNumber' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.segmentNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="carrierMarketing">
              <mat-header-cell *matHeaderCellDef>{{ 'field.mktCarrier' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="flightNumber" id="detail-list-waiver-latam-flightNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.mktFlightNumber' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="class" id="detail-list-waiver-latam-class">
              <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="source" id="detail-list-waiver-latam-source">
              <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.source | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="destination" id="detail-list-waiver-latam-destination">
              <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.destination | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="departureDateTime" id="detail-list-waiver-latam-departureDateTime">
              <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef>{{ 'field.segmentStatus' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsS" class="waiver-latam-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsS;" class="waiver-latam-row"></mat-row>
          </mat-table>
        </div>
      </ng-container>

      <!-- inf tkt -->
      <ng-container *ngIf="objectWaiver.document == 'tkt'">
        <div class="row mt-4 mr-0 ml-0">
          <div class="col text-center title">
            <p>{{ 'tktDetail' | translate }}</p>
          </div>
        </div>

        <!-- Info ticket -->
        <div class="row ml-3 mr-3">
          <div class="col">
            <span class="subtitle">{{ 'tktInformation' | translate }}</span>
          </div>
        </div>

        <div class="table-waiver-latam mat-elevation-z1">
          <mat-table [dataSource]="dataTkt">
            <ng-container matColumnDef="passengerTKT">
              <mat-header-cell *matHeaderCellDef>{{ 'field.tkt' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pnrNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pnr' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentTktPnrReference | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="emisionCreationDate">
              <mat-header-cell *matHeaderCellDef>{{ 'field.issueDate' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ data.documentCreationDate | dateFormat: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

             <ng-container matColumnDef="pos" id="detail-list-waiver-latam-tkt-pos">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pos' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentPos | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="iata" id="detail-list-waiver-latam-tkt-iata">
              <mat-header-cell *matHeaderCellDef>{{ 'field.iata' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentIata | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="pcc" id="detail-list-waiver-latam-tkt-pcc">
              <mat-header-cell *matHeaderCellDef>{{ 'field.pcc' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentPcc | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="officeId" id="detail-list-waiver-latam-tkt-officeId">
              <mat-header-cell *matHeaderCellDef>{{ 'field.officeId' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.documentOfficeId | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsT" class="waiver-latam-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsT" class="waiver-latam-row"></mat-row>
          </mat-table>
        </div>

        <!-- Info cupón -->
        <div class="row mt-4 ml-3 mr-3">
          <div class="col-12">
            <span class="subtitle">{{ 'couponInformation' | translate }}</span>
          </div>
        </div>

        <div class="table-waiver-latam mat-elevation-z1 mb-3">
          <mat-table [dataSource]="dataVoucher">
            <ng-container matColumnDef="voucherNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.couponNumber' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.couponNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="carrierMarketing">
              <mat-header-cell *matHeaderCellDef>{{ 'field.carrier' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.carrierMarketing | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="flightNumber">
              <mat-header-cell *matHeaderCellDef>{{ 'field.flightNumber' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.flightNumber | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="class" id="detail-list-waiver-latam-cup-class">
              <mat-header-cell *matHeaderCellDef>{{ 'field.class' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.class | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="source" id="detail-list-waiver-latam-cup-source">
              <mat-header-cell *matHeaderCellDef>{{ 'field.from' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.source | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="destination" id="detail-list-waiver-latam-cup-destination">
              <mat-header-cell *matHeaderCellDef>{{ 'field.to' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.destination | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="departureDateTime" id="detail-list-waiver-latam-cup-departureDateTime">
              <mat-header-cell *matHeaderCellDef>{{ 'field.departureDate' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">
                {{ data.departureDateTime | dateFormatWithoutLocale: 'DD-MMM-YY HH:mm' | uppercase | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="farebasis">
              <mat-header-cell *matHeaderCellDef>{{ 'field.farebasis' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.fareBasis | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="voucherStatus">
              <mat-header-cell *matHeaderCellDef>{{ 'field.couponStatus' | translate }}</mat-header-cell>
              <mat-cell *matCellDef="let data">{{ data.status | dashWhenEmpty }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsC" class="waiver-latam-row"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsC;" class="waiver-latam-row"></mat-row>
          </mat-table>
        </div>
      </ng-container>
    </div>

    <!-- forms aprobar - rechazar - agencia pendiente-->
    <ng-container *ngIf="tab == 'pendientes' && profiles.updateWaiverPostSaleManagement">
      <div class="btn-div">
        <div [@slideInOut]="animationState" class="divFooter">
          <mat-card class="edit-waiver">
            <mat-card-content>
              <mat-tab-group #tabGroup class="tab-group"
                [ngClass]="{'tab-min': approveReject, 'tab-max': !approveReject }"
                (selectChange)="toggleShowTab('divFooter')">
                <mat-tab>
                  <ng-template mat-tab-label>
                    <div (click)="toggleShowTab('divFooter')">{{ 'button.approve' | translate }}</div>
                    <div class="bottom-border-approve"> </div>
                  </ng-template>

                  <form>
                    <div *ngIf="correctNameCheck" class="row mt-4 ml-3 mr-3 ml-0 mr-0">
                      <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="row ml-0 mr-0 justify-content-center">
                          <div class="col-12 col-lg-12 col-xl-12 pl-0 pr-0 text-left">
                            <mat-checkbox ngDefaultControl id="correctNameCheck" (change)=nAplicaFolioWaiver() name="correctNameCheck" type="checkbox"
                             >{{ 'field.notApplicable' | translate }}
                            </mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 ml-3 mr-3 ml-0 mr-0">
                      <div class="col-6 col-md-3 col-lg-3 col-xl-3" [hidden]="isSoftBenefitsAndMarket()">
                        <mat-form-field>
                          <mat-label>{{ 'field.waiverRecord' | translate }}</mat-label>
                          <input matInput id="folioWaiverInput" name="folio" #folio="ngModel" pattern="([0-9])+" required [maxlength]="20"
                            [(ngModel)]="folioWaiver" [disabled]="checkCorrectNameStatus"/>
                        </mat-form-field>
                      </div>

                      <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                        <mat-form-field>
                          <mat-label>{{ 'field.waiverValueUsd' | translate }}</mat-label>
                          <input matInput id="waiverValueInput" name="wValue" #wValue="ngModel"
                            pattern="([0-9])+((.|,)[0-9][0-9]?)?" required [maxlength]="20" [(ngModel)]="waiverValue" [disabled]="checkCorrectNameStatus" />
                        </mat-form-field>
                      </div>

                      <div class="col-6 col-md-3 col-lg-3 col-xl-3">
                        <textarea matInput name="approvalComment" #messageAprobar maxlength="500" [(ngModel)]="approvalComment"
                          placeholder="{{ 'field.enterComment' | translate }}" class="textarea-approve"></textarea>
                        <mat-hint class="comment-hint">{{ messageAprobar.value.length }} / 500</mat-hint>
                      </div>

                      <div class="col-6 col-md-3 col-lg-3 col-xl-3 pl-0">
                        <div class="row ml-0 mr-0 justify-content-center">
                          <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 text-center">
                            <mat-checkbox ngDefaultControl id="approveCheck" name="confirmApproval" type="checkbox"
                              [(ngModel)]="confirmApproval">{{ 'field.confirm' | translate }}
                            </mat-checkbox>
                          </div>
                        </div>

                        <div *ngIf="dataSolicitud.data[0].motive.code !== softBenefits" class="row ml-0 mr-0 justify-content-center">
                          <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0"
                            *ngIf="profiles.updateWaiverPostSaleManagement">
                            <button mat-raised-button id="approveWaiverButton"
                              [disabled]="!confirmApproval || !folio.value || (folio.errors && (folio.dirty || folio.touched)) || !wValue.value || (wValue.errors && (wValue.dirty || wValue.touched))"
                              (click)="approveWaiver()"
                              class="btn-approval w-100">{{ 'button.approveRequest' | translate }}</button>
                          </div>
                        </div>

                        <div *ngIf="dataSolicitud.data[0].motive.code === softBenefits" class="row ml-0 mr-0 justify-content-center">
                          <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0"
                            *ngIf="profiles.updateWaiverPostSaleManagement">
                            <button mat-raised-button id="approveWaiverButton"
                              [disabled]="!confirmApproval || !wValue.value || (wValue.errors && (wValue.dirty || wValue.touched))"
                              (click)="approveWaiver()"
                              class="btn-approval w-100">{{ 'button.approveRequest' | translate }}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label id="detail-list-waiver-latam-deny">
                    <div (click)="toggleShowTab('divFooter')">{{ 'button.deny' | translate }}</div>
                  </ng-template>

                  <form #rechazoWaiverForm="ngForm">
                    <div class="row mt-4 ml-3 mr-3 ml-0 mr-0">
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4" id="detail-list-waiver-latam-motive-reject">
                        <mat-form-field>
                          <mat-label>{{ 'field.reason' | translate }}</mat-label>
                          <mat-select ngDefaultControl name="motivo" #motive="ngModel" required [(ngModel)]="selectedMotive">
                            <mat-option *ngFor="let motive of motives" [value]="motive">{{ motive.name }}
                            </mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="motive.errors && (motive.dirty || motive.touched) && motive.errors.required">
                            {{ 'field.error.selectReasonRejection' | translate }}</mat-error>
                        </mat-form-field>
                      </div>

                      <div class="col-6 col-md-4 col-lg-4 col-xl-4" id="detail-list-waiver-latam-rejection-comment">
                        <ng-container *ngIf="selectedMotive.id != -1">
                          <textarea matInput name="rejectionComment" #messageRechazar maxlength="500" [required]="selectedMotive.id == 4"
                            placeholder="{{ 'field.enterComment' | translate }}" [(ngModel)]="rejectionComment" class="textarea-approve"></textarea>
                          <mat-hint class="comment-hint">{{ messageRechazar.value.length }} / 500</mat-hint>
                        </ng-container>
                      </div>

                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 offset-6 offset-md-0 offset-lg-0 offset-xl-0" id="detail-list-waiver-latam-confirm">
                        <div class="row ml-0 mr-0 justify-content-center">
                          <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 text-center">
                            <mat-checkbox ngDefaultControl id="rejectCheck" name="confirmRejection" type="checkbox"
                              [(ngModel)]="confirmRejection">
                              {{ 'field.confirm' | translate }}</mat-checkbox>
                          </div>
                        </div>

                        <div class="row ml-0 mr-0 justify-content-center" id="detail-list-waiver-latam-reject-button">
                          <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 "
                            *ngIf="profiles.updateWaiverPostSaleManagement">
                            <button mat-raised-button id="rejectWaiverButton"
                              [disabled]="!confirmRejection || selectedMotive.id == -1 || rechazoWaiverForm.invalid"
                              (click)="rejectWaiver()"
                              class="background-red w-100">{{ 'button.denyRequest' | translate }}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </mat-tab>


                <mat-tab *ngIf="hasFileAttachment()">
                  <ng-template mat-tab-label>
                    <div (click)="toggleShowTab('divFooter')">{{ 'pendingAgency' | translate}}</div>
                    <div class="bottom-pending-agency"> </div>
                  </ng-template>

                  <form #pendingAgencyWaiver="ngForm">
                    <div class="row mt-4 ml-3 mr-3 ml-0 mr-0 justify-content-center">
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4">
                        <ng-container>
                          <textarea matInput name="pendingComment" #pendingObservations maxlength="500"
                            placeholder="{{ 'field.enterComment' | translate }}" [(ngModel)]="pendingComment" class="textarea-approve"></textarea>
                          <mat-hint class="comment-hint">{{ pendingObservations.value.length }} / 500</mat-hint>
                        </ng-container>
                      </div>

                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 offset-6 offset-md-0 offset-lg-0 offset-xl-0">
                        <div class="row ml-0 mr-0 justify-content-center">
                          <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 text-center">
                            <mat-checkbox ngDefaultControl id="pendingAgencyCheck" name="confirmPendingAgency" type="checkbox"
                              [(ngModel)]="confirmPendingAgency">
                              {{ 'field.confirm' | translate }}</mat-checkbox>
                          </div>
                        </div>

                        <div class="row ml-0 mr-0 justify-content-center">
                          <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0 "
                            *ngIf="profiles.updateWaiverPostSaleManagement">
                            <button mat-raised-button id="rejectWaiverButton"
                              [disabled]="!confirmPendingAgency"
                              (click)="pendingFromAgencyWaiver()"
                              class="background-red w-100">Enviar observaciones</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </mat-tab>
              </mat-tab-group>
            </mat-card-content>
          </mat-card>
        </div>

        <button mat-raised-button *ngIf="tab == 'pendientes'"
          (click)="approveReject = !approveReject; toggleShowDiv('divFooter')" class="hide-button">
          <mat-icon matSuffix>{{ approveReject ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
