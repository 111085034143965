import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';

import { Profile } from '../../../models/profile.model';
import { SidebarMenuModel } from '../../../models/SidebarMenu.model';

import { ProfilingService } from '../../../services/profiling.service';
import { SidebarShareDataService } from '../../../services/sidebar-share-data.service';


@Component({
  selector: 'app-list-waiver-agency-new',
  templateUrl: './list-waiver-agency-new.component.html',
  styleUrls: ['./list-waiver-agency-new.component.css']
})
export class ListWaiverAgencyNewComponent implements OnInit {

  localeLanguage: string;
  displayedColumnsPendingStatus = ['id', 'type', 'motive', 'documentNumber', 'agent', 'creationDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsPendingAgencyStatus = ['id', 'type', 'motive', 'documentNumber', 'agent', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsApprovedStatus = ['id', 'type', 'motive', 'documentNumber', 'agent', 'statusDate', 'documentCloserDepartureDate', 'actions'];
  displayedColumnsRejectedStatus = ['id', 'type', 'motive', 'documentNumber', 'agent', 'statusDate', 'documentCloserDepartureDate', 'actions'];

  userProfiles = new Profile();
  sidebarMenuAuto: SidebarMenuModel = new SidebarMenuModel(SidebarMenuModel.MENU_WAIVERS, SidebarMenuModel.SUBMENU_NEW_LATAM_WAIVER_REQUEST);
  tab:string;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private profilesService: ProfilingService,
    private sidebarShareDataService: SidebarShareDataService) {
      this.localeLanguage = locale;
  }

  ngOnInit() {
    this.userProfiles = this.profilesService.getProfile();
    this.sidebarShareDataService.changePanelOpened(this.sidebarMenuAuto);
  }

}
