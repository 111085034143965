<div *ngIf="isAuthAndAuthorized && isUrlValid" class="container-fluid">
  <div class="row">
    <div *ngIf="showMainLayout" class="col pr-0 pl-0">
      <app-header></app-header>
    </div>

    <div *ngIf="!showMainLayout" class="col pr-0 pl-0">
      <app-waiver-latam-header></app-waiver-latam-header>
    </div>
  </div>

  <div class="row h-100">
    <div class="col pr-0 pl-0">
      <app-menu [showMainLayout]="showMainLayout"></app-menu>
      <!-- <app-loader></app-loader> -->
    </div>
  </div>

  <div class="row">
    <div class="col pr-0 pl-0">
      <app-footer></app-footer>
    </div>
  </div>
</div>

<ng-container *ngIf="!isAuthAndAuthorized && isUrlValid">
  <app-login></app-login>
</ng-container>

<ng-container *ngIf="!isUrlValid">
  <app-error-page></app-error-page>
</ng-container>
