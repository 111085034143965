import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import * as jwt_decode from "jwt-decode";
import { LatamLoginResponse } from '../../../interfaces/latam-login-response.interface';
import { TokenResponse } from '../../../interfaces/token-interface';
import { LoaderService } from '../../../services/loader.service';
import { LoginLatamService } from '../../../services/login-latam.service';
import { LoginService } from '../../../services/login.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-login-latam',
  templateUrl: './login-latam.component.html',
  styleUrls: ['./login-latam.component.css']
})
export class LoginLatamComponent implements AfterViewInit {

  @Output() message: EventEmitter<string> = new EventEmitter();
  @ViewChild('loginGoogleBtn') loginGoogleBtn: ElementRef;


  constructor(
    private loaderService: LoaderService,
    private loginLatamService: LoginLatamService,
    private loginService: LoginService
  ) { }

  ngAfterViewInit() {
    const client_id = StorageService.getClientId();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: client_id,
      callback: (response: any) => this.handleCredentialResponse(response)
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    google.accounts.id.renderButton(
      //document.getElementById("buttonDiv")
      this.loginGoogleBtn.nativeElement,
      { theme: "filled_blue", size: "large", shape: "circle" }  // customization attributes
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    google.accounts.id.prompt();
  }

  handleCredentialResponse(response: LatamLoginResponse) {
    const decodedTokenTemp: TokenResponse = jwt_decode(response.credential);

    this.loaderService.show();
    localStorage.setItem('userN', decodedTokenTemp.name);
    localStorage.setItem('userMail', decodedTokenTemp.email);
    localStorage.setItem('idToken', response.credential);

    this.loginLatamService.isAuthorized().subscribe({
      next: resp => {
        localStorage.setItem('sessionToken', resp.sessionToken);
        this.loginService.setCurrentLoginState(true);
        // workaround
        window.location.href = "/latam/home";
        window.location.reload();
        // fin workaround
        this.loaderService.hide();
      },
      error: (error: HttpErrorResponse) => {
        this.loginService.setCurrentLoginState(false);

        if (error.status === 401 || error.status === 403 || error.status === 404) {
          this.message.emit('login.error.400');
        } else if (error.status === 500) {
          this.message.emit('login.error.500');
        } else {
          this.message.emit('login.error.error');
        }

        this.loaderService.hide();
        console.log('error', error);
      }
  });
  }

}
