import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { WaiverPendingAgency } from '../../../interfaces/waiver-pending-agency.interface';

import { Profile } from '../../../models/profile.model';
import { WaiverRejectionMotive } from '../../../models/waiver-rejection-motive.model';

import { MessageService } from '../../../services/message.service';
import { ProfilingService } from '../../../services/profiling.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { MessageErrorService } from '../../../services/message-error.service';
import { slideInOutAnimation } from '../../../constants/constants';


@Component({
  selector: 'app-detail-list-waiver-latam',
  templateUrl: './detail-list-waiver-latam.component.html',
  styleUrls: ['./detail-list-waiver-latam.component.css'],
  animations: [slideInOutAnimation],
  encapsulation: ViewEncapsulation.None
})
export class DetailListWaiverLatamComponent implements OnInit {
  approveReject = true;
  confirmApproval = false;
  correctNameCheck = false;
  confirmRejection = false;
  confirmPendingAgency = false;
  loading = false;
  loadingData = false;
  checkCorrectNameStatus= false;

  idParam = 0;

  animationState = 'out';
  approvalComment: string;
  // currencyCode: string;
  folioWaiver: string;
  rejectionComment: string;
  pendingComment: string;
  tab = '';
  // taxCurrencyCode: string;
  waiverValue: string;

  dataAprobacion = new MatTableDataSource();
  dataRechazo = new MatTableDataSource();
  dataReservation = new MatTableDataSource();
  dataPassengers = new MatTableDataSource();
  dataSegments = new MatTableDataSource();
  dataSolicitud = new MatTableDataSource();
  dataTkt = new MatTableDataSource();
  dataVoucher = new MatTableDataSource();
  displayedColumns = ['solicitante', 'agency', 'tier', 'iata', 'tipo', 'motivo', 'date_hour'];
  displayedColumnsA = ['nameAprob', 'dateAprob', 'commentAprob', 'folioWaiverAprob', 'waiverValueAprob'];
  displayedColumnsC = ['voucherNumber', 'carrierMarketing', 'flightNumber', 'class', 'source',
    'destination', 'departureDateTime', 'farebasis', 'voucherStatus'];
  displayedColumnsCo = ['pnrAgencia', 'files', 'coment'];
  displayedColumnsI = ['passengerName', 'foid', 'passengerType', 'passengerTKT'];
  displayedColumnsP = ['pnrNumber', 'pnrType', 'reservationCreationDate', 'numberOfPassengers', 'pos', 'iata', 'pcc', 'officeId'];
  displayedColumnsR = ['nameRech', 'dateRech', 'motivoRech', 'commentRech'];
  displayedColumnsS = ['segmentNumber', 'carrierMarketing', 'flightNumber', 'class',
    'source', 'destination', 'departureDateTime', 'status'];
  displayedColumnsT = ['passengerTKT', 'pnrNumber', 'emisionCreationDate', 'pos', 'iata', 'pcc', 'officeId']; // , 'tktUsd', 'taxUsd'];
  softBenefits = '107';
  motives: {};
  objectWaiver = {
    id: 0,
    document: '',
    documentNumber: ''
  };
  profiles = new Profile();
  selectedMotive: WaiverRejectionMotive = new WaiverRejectionMotive(-1, '');

  constructor(
    public dialogRef: MatDialogRef<DetailListWaiverLatamComponent>,
    private messageService: MessageService,
    private messageErrorService: MessageErrorService,
    private profilesService: ProfilingService,
    private translateService: TranslateService,
    private waiverPostsaleService: WaiverPostsaleService) { }

  ngOnInit() {
    this.idParam = this.objectWaiver.id;
    console.log(this.objectWaiver);
    this.profiles = this.profilesService.getProfile();
    this.getWaiver();
    this.getRejectionMotives();
  }

  /**
   * returns the detailed information of a waiver request
   */
  private getWaiver(): void {
    this.loadingData = true;

    this.waiverPostsaleService.getWaiverById(this.objectWaiver.id).subscribe({
      next: data => {
        this.dataRechazo.data = [{
          statusUser: data['statusUser'],
          statusDate: data['statusDate'],
          statusComment: data['statusComment'],
          rejectionMotive: data['rejectionMotive']
        }];

        this.dataAprobacion.data = [{
          statusUser: data['statusUser'],
          statusDate: data['statusDate'],
          statusComment: data['statusComment'],
          folio: data['folio'],
          valorization: data['valorization']
        }];

        this.dataSolicitud.data = [{
          id: data['id'],
          user: data['user'],
          agency: data['agency'],
          agencyGroup: data['agencyGroup'],
          branchOffice: data['branchOffice'],
          type: data['type'],
          motive: data['motive'],
          documentIata: data['documentIata'],
          creationDate: data['creationDate'],
          aditionalComment: data['aditionalComment'],
          pnrAgency: data['pnrAgency'],
          files: data['files'],
          market: data['market']
        }];

        if (data['type']['id']===12){

          this.correctNameCheck=true;

        }else{
          this.correctNameCheck=false;
        }

        this.dataReservation.data = [{
          documentNumber: data['documentNumber'],
          documentPnrType: data['documentPnrType'],
          documentCreationDate: data['documentCreationDate'],
          documentPos: data['documentPos'],
          documentIata: data['documentIata'],
          documentPcc: data['documentPcc'],
          documentOfficeId: data['documentOfficeId']
        }];

        this.dataPassengers.data = data['passengers'];

        this.dataSegments.data = data['segments'];

        this.dataTkt.data = [{
          id: data['id'],
          documentNumber: data['documentNumber'],
          documentTktPnrReference: data['documentTktPnrReference'],
          documentCreationDate: data['documentCreationDate'],
          documentPos: data['documentPos'],
          documentIata: data['documentIata'],
          documentPcc: data['documentPcc'],
          documentOfficeId: data['documentOfficeId'],
          documentTktAmount: data['documentTktAmount'],
          documentTktTax: data['documentTktTax']
        }];

        this.dataVoucher.data = data['coupons'];
        this.loadingData = false;

      },
      error: (error: HttpErrorResponse) => {
        this.closeDialog();
        this.messageErrorService.sendError(error, 'error.loadingWaiver');

        this.loadingData = false;
        console.log('error', error);
      }
  });
  }

  nAplicaFolioWaiver(){

    if (!this.checkCorrectNameStatus){

      this.checkCorrectNameStatus=true;
      this.folioWaiver='0';
      this.waiverValue='0';



    }else{


      this.checkCorrectNameStatus=false;
      this.folioWaiver=null
      this.waiverValue=null;


    }



  }

  private getRejectionMotives(): void {

    this.waiverPostsaleService.getRejectionMotives().subscribe({
      next: data => {
        this.motives = data;
      },
      error: (error: HttpErrorResponse) => {
        this.loadingData = false;
        console.log('error', error);
      }
  });
  }

  /**
   * method to close the modal
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * consult the rest service to approve a waiver
   */
  approveWaiver() {
    this.loading = true;

    this.waiverPostsaleService.putApproveWaiver(
      this.objectWaiver.id, this.approvalComment, this.folioWaiver, this.waiverValue.replace(',', '.')).subscribe({
        next: data => {
          this.dialogRef.close('reload-approved');

          this.messageService.showSuccessMessage(
            this.translateService.instant('button.back'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('success.aprovedWaiver', { waiverId: this.objectWaiver.id.toString() })
          );

          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.dialogRef.close('error');

          this.messageErrorService.sendError(error, 'error.aprovedWaiver');

          this.loading = false;
          console.log('error', error);
        }
  });
  }

  /**
   * consult the rest service to reject a waiver
   */
  rejectWaiver() {
    this.loading = true;

    this.waiverPostsaleService.putRejectWaiver(this.objectWaiver.id, this.selectedMotive.id, this.rejectionComment).subscribe({
      next: data => {
        this.dialogRef.close('reload-rejected');

        this.messageService.showSuccessMessage(
          this.translateService.instant('button.back'),
          this.translateService.instant('popup.wellDone'),
          this.translateService.instant('success.deniedWaiver', { waiverId: this.objectWaiver.id.toString() })
        );

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.dialogRef.close('error');
        this.messageErrorService.sendError(error, 'error.deniedWaiver');

        this.loading = false;
        console.log('error', error);
      }});
  }

  pendingFromAgencyWaiver(){
    this.loading = true;
    const waiverPendingAgency: WaiverPendingAgency = {
      motive: 'Prueba',
      comment: this.pendingComment
    };

    this.waiverPostsaleService.setPendingAgencyWaiver(waiverPendingAgency, this.objectWaiver.id)
      .subscribe({
        next: (resp) => {
          this.dialogRef.close('reload-pending-agency');

          this.messageService.showSuccessMessage(
            this.translateService.instant('button.back'),
            this.translateService.instant('popup.wellDone'),
            this.translateService.instant('success.observationsSent')
          );

          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.dialogRef.close('error');

          this.messageErrorService.sendError(error, 'error.observationsNotSent');

          this.loading = false;
          console.log('error', error);
        }
  });
  }

  toggleShowDiv(divName: string) {
    if (divName === 'divFooter') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
    }
  }

  toggleShowTab(divName: string) {
    if (divName === 'divFooter' && this.approveReject) {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
      this.approveReject = false;
    }
  }

  hasFileAttachment(){
    const infoSolicitud: any = this.dataSolicitud.data[0];
    return infoSolicitud.motive.attachmentFile;
  }

  isSoftBenefitsAndMarket(){
    const infoSolicitud: any = this.dataSolicitud.data[0];
    return infoSolicitud.motive.code === this.softBenefits && infoSolicitud.market.name !== 'PE';
  }
}
