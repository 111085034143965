
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { Agency } from '../models/agency.model';
import { AgencyChannel } from './../models/agency-channel.model';
import { AgencyCreate } from '../models/agency-create.model';
import { AgencyCSV } from '../models/agencycsv.model';
import { AgencyDetail } from '../models/agency-detail.model';
import { AgencyPage } from '../models/agency-page.model';
import { AgencyProfile } from '../models/agency-profile.model';
import { AgencyResume } from '../models/agency-resume.model';
import { AgencySimplified } from '../models/agency-simplified.model';
import { Pagination } from '../models/pagination.model';

import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { AgencyCdp } from '../interfaces/agency.interface';
import { AgencyStatus } from '../interfaces/agency-status.interface';
import { StorageService } from './storage.service';


@Injectable()
export class AgencyService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlAgency}`;

  agency : AgencyCdp;

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService,
    private sessionInfoService: SessionInfoService) { }

  getAgencyListByAgencyGroupId(agencyGroupId: number, pageNumber: number, pageSize: number):
    Observable<{ agencySimplified: AgencySimplified[], pagination: Pagination }> {
    const locale = this.routeLocaleExtractorService.getLocale();
    let agencySimplified: AgencySimplified[] = [];
    let pagination = new Pagination();

    return this.http.get<{ agencySimplified: AgencySimplified[], pagination: Pagination }>(
      `${this.urlBase}${locale}/groups/agencies?groupId=${agencyGroupId}`,
      {
        params: new HttpParams()
          .set('page', pageNumber.toString())
          .set('size', pageSize.toString())
          .set('sort', 'id')
      }
    ).pipe(map(
      resp => {
        agencySimplified = resp['content'];
        pagination.totalElements = resp['totalElements'];
        pagination.pageIndex = resp['number'];
        pagination.pageSize = resp['size'];

        return { agencySimplified, pagination };
      }
    ));
  }

  /**
   * Obtiene las agencias con estado 'active' para el market del usuario en sesion
   * @returns `Observable<AgencyCSV[]>`
   */
  getAgencyListByPos(): Observable<AgencyCSV[]> {
    const agencyCSV = [];
    const locale = this.routeLocaleExtractorService.getLocale();
    const sessionInfo = this.sessionInfoService.getSessionInfo();

    return this.http.get<AgencyCSV[]>(
      `${this.urlBase}${locale}/markets/agencies?markets=${sessionInfo.marketId}`
    ).pipe(map(data => {
      data.map((value: any) => {
        agencyCSV.push(
          new AgencyCSV(
            value.id,
            value.iata,
            value.name,
            value.agencyCode,
            value.agencyBranch,
            value.officeId,
            value.pcc,
            value.agencyGroup ? (value.agencyGroup.id ? value.agencyGroup.id : null) : null,
            value.agencyGroup ? (value.agencyGroup.name ? value.agencyGroup.name : null) : null,
            value.market.id,
            value.market.name,
            value.city,
            value.country,
            value.executive,
            value.active,
            value.piaAgencyCode,
            value.piaAgencyType)
        );
      });

      return agencyCSV;
    }));
  }


  createAgency(agencyCreate: AgencyCreate): Observable<Agency> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<Agency>(`${this.urlBase}${locale}/agencies`, agencyCreate, { headers: this.headers });
  }

  getAgencyByCustomerIdAndOfficeCode(customerId: number, officeCode: number) {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get(`${this.urlBase}${locale}/agencies/exist?customerId=${customerId}&officeCode=${officeCode}`).pipe(
      map(data => {
        return data;
      }));
  }

  getAgencyExistByIata(iata: string) {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get(`${this.urlBase}${locale}/agencies/exist?iata=${iata}`).pipe(
      map(data => {
        return data;
      }));
  }

  getAgencyStatusByIata(iata: string): Observable<AgencyStatus>{
    const locale = this.routeLocaleExtractorService.getLocale();

    return (this.http.get(`${this.urlBase}/status/iata/${iata}`) as Observable<AgencyStatus>);
  }

  /**
   * Obtiene la información de una agencia a partir de su IATA, la búsqueda se realiza a través
   * del servicio de búsqueda de agencias proporcionado por el servicio ADMC de LATAM
   * @param `string` iata
   * @returns `Observable<any[]>`
   */
  getAgencyByIata(iata: string): Observable<AgencyCdp> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return (this.http.get(`${this.urlBase}/iatacode/${iata}`) as Observable<AgencyCdp>);
  }

  /**
   * Obtiene la información de una agencia a partir de su nombre, la búsqueda se realiza a través
   * del servicio de búsqueda de agencias proporcionado por el servicio ADMC de LATAM
   * @param `string` name
   * @returns `Observable<any[]>`
   */
  getAgencyByName(name: string): Observable<any[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get(`${this.urlBase}${locale}/agencies/search?name=${name}`).pipe(
      map(data => {
        return data['customersHolding'];
      }));
  }

  /**
   * Obtiene el listado de channels
   * @returns `Observable<AgencyChannel[]>`
   */
  getAgencyChannelList(): Observable<AgencyChannel[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<AgencyChannel[]>(`${this.urlBase}${locale}/agencies/channels`);
  }

  getAgencyDetail(agencyCode: number, agencyBranch: number): Observable<AgencyDetail> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<AgencyDetail>(`${this.urlBase}${locale}/agencies/detail?agencyCode=${agencyCode}&agencyBranch=${agencyBranch}`);
  }

  /**
   * Obtiene las agencias con estado 'active' para el market del usuario en sesion
   * @returns `Observable<{ agencies: AgencyPage[], pagination: Pagination }>`
   */
  getAgencyListActivePos(pageNumber: number, pageSize: number, agency: AgencyPage):
    Observable<{ agencies: AgencyPage[], pagination: Pagination }> {
    const agencies: AgencyPage[] = [];
    const locale = this.routeLocaleExtractorService.getLocale();
    const pagination = new Pagination();
    const parametros = new HttpParams()
      .set('page', pageNumber + '')
      .set('size', pageSize + '')
      .set('name', agency.name + '')
      .set('iata', agency.iata + '')
      .set('city', agency.city + '')
      .set('agencyTierName', agency.agencyTierName + '')
      .set('executive', agency.executive + '')
      .set('status', agency.status + '')
      .set('marketName', agency.marketName + '');
    const sessionInfo = this.sessionInfoService.getSessionInfo();

    return this.http.get<{ agencies: AgencyPage[], pagination: Pagination }>(
      `${this.urlBase}${locale}/agencies/markets?markets=${sessionInfo.marketId}`, { params: parametros }
    ).pipe(map(data => {
      pagination.totalElements = data['totalElements'];
      pagination.pageIndex = data['number'];
      pagination.pageSize = data['size'];
      data['content'].map(
        value => {
          agencies.push(
            {
              id: value.id,
              name: value.name,
              iata: value.iata,
              city: value.city,
              agencyTierName: value.agencyTierName,
              executive: value.executive,
              status: value.status,
              marketName: value.marketName
            }
          );
        }
      );

      return { agencies, pagination };
    }));
  }

  getAgencyProfile(agencyId: number): Observable<AgencyProfile> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<AgencyProfile>(`${this.urlBase}${locale}/agencies/${agencyId}`);
  }

  deactivateAgency(agencyId: number): Observable<Agency> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.put<Agency>(`${this.urlBase}${locale}/agencies/agency/${agencyId}/deactivate`,
      {}).pipe(
      map(data => {
        return data;
      }));
  }

  updateAgency(agencyResume: AgencyResume): Observable<AgencyResume> {
    const locale = this.routeLocaleExtractorService.getLocale();

    if (agencyResume.agencyBranchOffice.id === 0) {
      agencyResume.agencyBranchOffice = null;
    }

    return this.http.put<AgencyResume>(`${this.urlBase}${locale}/agencies/${agencyResume.id}`, agencyResume, { headers: this.headers });
  }

  reactivateAgency(agencyId: number): Observable<Agency> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.put<Agency>(`${this.urlBase}${locale}/agencies/agency/${agencyId}/reactivate`,
      {}).pipe(
      map(data => {
        return data;
      }));
  }

}
