import { WaiverCategory } from './waiver-category.model';
import { WaiverStatus } from './waiver-status.model';
import { WaiverType } from './waiver-type.model';
import { AgencyProfile } from './agency-profile.model';
import { Market } from './market.model';
import { AgencyGroup } from './agency-group.model';
import { BranchOffice } from './branch-office.model';
import { OsiInfo } from './osi-info.model';

export class Waiver {
  id: number;
  type: WaiverType;
  motive: {};
  category: WaiverCategory;
  status: WaiverStatus;
  user: {};
  agency: AgencyProfile;
  market: Market;
  creationDate: string;
  document: string;
  documentNumber: string;
  documentPos: string;
  documentIata: string;
  documentPcc: string;
  documentOfficeId: string;
  documentCreationDate: string;
  documentPnrType: string;
  documentTktAmount: string;
  documentTktCurrency: string;
  documentTktTax: string;
  documentTktTaxCurrency: string;
  aditionalComment: string;
  statusDate: string;
  statusUser: string;
  statusComment: string;
  folio: string;
  pnrAgency: string;
  osiInfo: OsiInfo;
  valorization: string = '0';
  rejectionMotive: string;
  documentTktPnrReference: string;
  files: any[];
  documentfare?: any;
  agencyGroup: AgencyGroup;
  branchOffice: BranchOffice;
  coupons: any[];
  passengers: any[];
  segments: any[];

  pnrNumber: string;
  ticket: string;
  pnr: {};
  tkt = {};
  booking = {};
  issuer = {};
  fare = {};
  remark: string;
  numberOfPassenger: number = 0;

  constructor(
    pnrNumber?: string,
    ticket?: string,
    pnr?: {},
    tkt?: {},
    booking?: {},
    issuer?: {},
    fare?: {},
    passengers?: any[],
    segments?: any[],
    coupons?: any[],
    type?: WaiverType,
    motive?: {},
    category?: WaiverCategory,
    document?: string,
    documentNumber?: string,
    documentPos?: string,
    documentCreationDate?: string,
    user?: {},
    aditionalComment?: string,
    pnrAgency?: string,
    osiInfo?: OsiInfo,
    files?: any[]) {
      this.pnrNumber = pnrNumber;
      this.ticket = ticket;
      this.pnr = pnr;
      this.tkt = tkt;
      this.booking = booking;
      this.issuer = issuer;
      this.fare = fare;
      this.passengers = passengers;
      this.segments = segments;
      this.coupons = coupons;
      this.type = type;
      this.motive = motive;
      this.category = category;
      this.document = document;
      this.documentNumber = documentNumber;
      this.documentPos = documentPos;
      this.documentCreationDate = documentCreationDate;
      this.user = user;
      this.aditionalComment = aditionalComment;
      this.pnrAgency = pnrAgency;
      this.osiInfo = osiInfo;
      this.files = files;
  }
}
