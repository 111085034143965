import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SessionInfoService } from '../../services/session-info.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  showLoginLatam = false;
  showLoginOutsourcing = false;
  showRecoveryPass = false;
  showResetPass = false;
  code = '';
  urlLogin = '';

  message: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sessionInfoService: SessionInfoService) { }

  ngOnInit() {
    this.showLogin();

    if (localStorage.getItem('idToken') && localStorage.getItem('sessionToken')) {
      this.sessionInfoService.redirectTypeOfSession();
    }    
  }

  showLogin() {
    this.showLoginLatam = false;
    let pathNames = window.location.pathname.split('/');

    for (let i = 0; i < pathNames.length; i++) {
      if (pathNames[i] === 'login') {
        if (pathNames[i + 1] === 'latam') {
          this.showLoginLatam = true;
          break;
        }
        if (pathNames[i + 1] === 'outsourcing') {
          this.showLoginOutsourcing = true;
          break;
        }
      }
    }

    if (!this.showLoginLatam) {
      this.activatedRoute.queryParams.subscribe(
        params => {
          this.showResetPass = params['mode'] === 'resetPassword';
          this.code = params['oobCode'];
          this.urlLogin = params['continueUrl'];
        }
      );
    }
  }

  setMessage(event: string) {
    this.message = event;
  }

  setShowRecoveryPass(event: boolean) {
    this.showRecoveryPass = event;
    this.message = '';
  }

}
