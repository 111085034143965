import { Injectable } from '@angular/core';

import { BudgetType } from '../models/budget-type.model';


@Injectable()
export class ConstantsService {
  readonly postsale = new BudgetType(1, 'After Sale');
  readonly presale = new BudgetType(2, 'Presale');
  readonly postsaleEs = new BudgetType(1, 'Post venta');
  readonly presaleEs = new BudgetType(2, 'Preventa');
  readonly postsalePt = new BudgetType(1, 'Pós-venda');
  readonly presalePt = new BudgetType(2, 'Pré-venda');
  readonly systemName = 'PORTAL AGENCIA';
  readonly changeFlightPenalty  = 2;
  readonly refundAllowedWithoutPenalty = 8;
  readonly commercialCourtesy = 1;
}
